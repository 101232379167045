* {
  box-sizing: border-box;
  text-decoration: none;
  color: #2B2B2B;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Hepta+Slab:wght@200;400;600&family=Public+Sans:wght@300;400;600&display=swap");