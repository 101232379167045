#picture__input {
  display: none;
}

.picture {
  width: 100%;
  position: relative;
  margin: 10px auto;
  height: 5rem;
  aspect-ratio: 16/9;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border: 2px dashed #4c6c8c;
  cursor: pointer;
  font-family: sans-serif;
  transition: color 300ms ease-in-out, background 300ms ease-in-out;
  outline: none;
  overflow: hidden;
  font-weight: 500;
  font-size: 1.4rem;
}

@media (min-width: 480px) {
  .picture {
    height: 15rem;
  }
}

.picture:hover {
  font-weight: 500;
  font-size: 1.4rem;
  color: #ffffff;
  background: #ffffff;
}

.picture:active {
  border-color: turquoise;
  color: turquoise;
  background: #ffffff;
}

.picture:focus {
  color: #ffffff;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.picture__img {
  color: #ffffff;
  width: 100%;
}
